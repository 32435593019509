import React from 'react'
import SEOTools from '../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import FaqItem from '../components/FaqItem'
import { graphql } from 'gatsby'
import HeroUnit from '../components/HeroUnit'
import { faFileInvoiceDollar, faGlobe, faQuestionCircle, faUserShield } from '@fortawesome/free-solid-svg-icons'
import ContactForm from '../components/Forms/ContactForm'
import FaqCategoryScrollButton from '../components/FaqCategoryScrollButton'
import { getSrc } from 'gatsby-plugin-image'
import StyledSideCol from '../components/styled/StyledSideCol'
import useLandbot from '../hooks/useLandbot'
import Layout from '../components/Layout'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const FaqPage = ({ data }) => {
    // Grab faq items from queried data
    const faqItems = data.faqItems.nodes
    // Split items based on category
    const top = faqItems.filter(item => item.category.includes('topQuestions'))
    const general = faqItems.filter(item => item.category.includes('general'))
    const invoices = faqItems.filter(item => item.category.includes('invoices'))
    const security = faqItems.filter(item => item.category.includes('security'))
    const transactions = faqItems.filter(item => item.category.includes('transactions'))

    useLandbot('https://chats.landbot.io/v3/H-976564-IJ7Y7QLOFW7SIDGS/index.json')

    return (
        <Layout>
            <SEOTools
                title='Support'
                description='Find answers for all your common questions on Siru!'
                image={getSrc(data.heroBg.childImageSharp)}>
                {/* eslint-disable-next-line */}
                <html {...schemaAttributes} />
            </SEOTools>

            <HeroUnit backgroundImage={data.heroBg}>
                <Row className='d-flex align-items-center justify-content-center text-center text-white my-5'>
                    <Col md='8'>
                        <h1>Looking for help? We've got you covered.</h1>
                    </Col>
                </Row>
            </HeroUnit>

            <section className='pt-2 mb-5'>
                <Container>
                    <Row className='my-5'>
                        <StyledSideCol md='3' className='mb-3'>
                            <div className='text-secondary top'>Company</div>
                            <div className='h3 bottom'>
                                <strong>Siru Mobile</strong>
                            </div>
                        </StyledSideCol>
                        <Col>
                            <p>
                                Welcome to our FAQ! Here you'll find answers to the most frequently asked questions. If
                                you can't find what you're looking for, please contact customer support.
                            </p>
                        </Col>
                    </Row>

                    <Row className='text-center mb-5'>
                        <FaqCategoryScrollButton icon={faQuestionCircle} text='General' scrollToId='general' />
                        <FaqCategoryScrollButton icon={faGlobe} text='Transactions' scrollToId='transactions' />
                        <FaqCategoryScrollButton icon={faFileInvoiceDollar} text='Invoices' scrollToId='invoices' />
                        <FaqCategoryScrollButton icon={faUserShield} text='Security' scrollToId='security' />
                    </Row>

                    <h2 className='mb-5'>Top 3 questions</h2>

                    {top.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='general' className='my-5 pt-4'>
                        General questions
                    </h2>

                    {general.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='transactions' className='my-5 pt-4'>
                        Transactions and limits
                    </h2>

                    {transactions.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='invoices' className='my-5 pt-4'>
                        Invoices
                    </h2>

                    {invoices.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='security' className='my-5 pt-4'>
                        Security
                    </h2>

                    {security.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <Row className='mt-5 mb-4 pt-5'>
                        <StyledSideCol md='3' className='mb-3'>
                            <div className='text-secondary top'>Contact us</div>
                            <div className='h3 bottom'>
                                <strong>Ask a question</strong>
                            </div>
                        </StyledSideCol>
                        <Col>
                            <p>
                                Questions, concerns, or feedback? Let us know how we can help you and our customer
                                support will get back to you.
                            </p>
                        </Col>
                    </Row>

                    <ContactForm name='contact' />
                </Container>
            </section>
        </Layout>
    )
}

/**
 * Page query can be dynamic with variables. Can only be run on a page level.
 */
export const query = graphql`
    query ($langKey: String) {
        # Get hero image
        heroBg: file(relativePath: { eq: "supportive-woman.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
        # Get faq items
        faqItems: allSanityFaqItem(filter: { i18n_lang: { eq: $langKey } }, sort: { fields: weight, order: ASC }) {
            nodes {
                slug {
                    current
                }
                question
                category
                _rawAnswer(resolveReferences: { maxDepth: 1 })
            }
        }
    }
`

const schemaAttributes = { itemScope: true, itemType: 'https://schema.org/FAQPage' }

export default FaqPage
